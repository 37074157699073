import { inter } from '@lib/fonts/google';
import { MantineThemeOverride } from '@mantine/core';
import Link from 'next/link';

export const theme: MantineThemeOverride = {
  fontFamily: `${inter.style.fontFamily}, system-ui, sans-serif`,
  black: '#18181b',
  white: '#ffffff',
  colors: {
    brand: [
      '#e5f2ff',
      '#cde2ff',
      '#9ac1ff',
      '#639fff',
      '#3682fe',
      '#196ffe',
      '#0066ff',
      '#0056e4',
      '#004cce',
      '#0041b6',
    ],
  },
  primaryColor: 'brand',
  primaryShade: { light: 6, dark: 7 }, // default 6 / 8
  defaultRadius: 'md',
  cursorType: 'pointer',
  components: {
    ActionIcon: {
      defaultProps: {
        variant: 'subtle',
        color: 'text',
      },
    },
    Anchor: {
      defaultProps: {
        component: Link,
      },
    },
    Badge: {
      defaultProps: {
        variant: 'light',
      },
    },
  },
};
