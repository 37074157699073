'use client';

import ClimbNavLink from '@components/climbs/ClimbNavLink';
import CragNavLink from '@components/crags/CragNavLink';
import UserNavLink from '@components/users/UserNavLink';
import { useSearch } from '@lib/swr/hooks/search';
import {
  ActionIcon,
  Button,
  Center,
  Group,
  Kbd,
  Loader,
  Text,
  Tooltip,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { Spotlight, spotlight } from '@mantine/spotlight';
import { IconSearch } from '@tabler/icons-react';
import { useState } from 'react';

export default function SearchModal() {
  const [query, setQuery] = useState<string>('');
  const [debounced] = useDebouncedValue(query, 200);
  const { results: climbs, isLoading } = useSearch('climbs', debounced);
  const { results: crags } = useSearch('crags', debounced);
  const { results: users } = useSearch('users', debounced);

  return (
    <>
      <Button
        variant='default'
        color='gray'
        visibleFrom='xs'
        onClick={spotlight.open}
      >
        <Group justify='center'>
          <IconSearch size={16} color='var(--mantine-color-dimmed)' />
          <Text c='dimmed' fw={400} pr={48}>
            Search
          </Text>
          <Kbd ml={5} size='xs'>
            /
          </Kbd>
        </Group>
      </Button>
      <Tooltip label='Search' color='gray'>
        <ActionIcon
          onClick={spotlight.open}
          size='lg'
          variant='default'
          hiddenFrom='xs'
        >
          <IconSearch size={16} />
        </ActionIcon>
      </Tooltip>

      <Spotlight.Root
        query={query}
        onQueryChange={setQuery}
        scrollable
        maxHeight={450}
        shortcut={['mod + P', 'mod + K', '/']}
      >
        <Spotlight.Search
          placeholder='Find your dong wall...'
          leftSection={<IconSearch stroke={1.5} />}
        />
        <Spotlight.ActionsList>
          {isLoading ? (
            <Spotlight.Empty>
              <Center>
                <Loader />
              </Center>
            </Spotlight.Empty>
          ) : (
            climbs?.length + crags?.length + users?.length <= 0 && (
              <Spotlight.Empty>Nothing found...</Spotlight.Empty>
            )
          )}
          <Spotlight.ActionsGroup label='Climbs'>
            {climbs?.map((climb) => (
              <Spotlight.Action key={climb._id} p={0}>
                <ClimbNavLink climb={climb} />
              </Spotlight.Action>
            ))}
          </Spotlight.ActionsGroup>

          <Spotlight.ActionsGroup label='Crags'>
            {crags?.map((crag) => (
              <Spotlight.Action key={crag._id} p={0}>
                <CragNavLink crag={crag} />
              </Spotlight.Action>
            ))}
          </Spotlight.ActionsGroup>

          <Spotlight.ActionsGroup label='Users'>
            {users?.map((user) => (
              <Spotlight.Action key={user._id} p={0}>
                <UserNavLink user={user} />
              </Spotlight.Action>
            ))}
          </Spotlight.ActionsGroup>
        </Spotlight.ActionsList>
      </Spotlight.Root>
    </>
  );
}
