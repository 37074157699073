import { Avatar, Indicator } from '@mantine/core';
import { stringToColor } from '@utils/text';

interface Props {
  user: User;
  size?: number;
  active?: boolean;
}

export default function UserAvatar({ user, size, active = false }: Props) {
  return (
    <Indicator color='primary' disabled={!active}>
      {user.image ? (
        <Avatar
          alt={user.username}
          src={user.image}
          style={{ width: size, height: size }}
        />
      ) : (
        <Avatar
          alt={user.username}
          style={{
            width: size,
            height: size,
            backgroundColor: stringToColor(user.name),
          }}
        >
          {user.name.split(' ')[0][0]}
          {user.name.split(' ')[1][0]}
        </Avatar>
      )}
    </Indicator>
  );
}
