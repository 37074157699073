import UserAvatar from '@components/users/UserAvatar';
import { Anchor, NavLink, Text } from '@mantine/core';
import FollowButton from './FollowButton';

const UserNavLink = ({ user }: { user: User }) => (
  <NavLink
    leftSection={<UserAvatar user={user} />}
    rightSection={<FollowButton user={user} />}
    label={
      <Anchor
        c='inherit'
        m={0}
        lh={1.25}
        fw='bold'
        href={`/users/${user.username}`}
      >
        {user.username}
      </Anchor>
    }
    description={
      <Text c='dimmed' fz='xs' m={0} lh={1.25}>
        {user.name}
      </Text>
    }
  />
);

export default UserNavLink;
