'use client';

import {
  Box,
  Center,
  SegmentedControl,
  useMantineColorScheme,
} from '@mantine/core';
import { IconMoonStars, IconSun } from '@tabler/icons-react';
import { useEffect } from 'react';
import classes from './SchemeControl.module.css';

export default function SchemeToggle() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const { setColorScheme } = useMantineColorScheme();

  useEffect(() => {
    setColorScheme(colorScheme);
  }, [colorScheme, setColorScheme]);

  return (
    <SegmentedControl
      fullWidth
      size='xs'
      value={colorScheme}
      onChange={() => toggleColorScheme()}
      classNames={classes}
      data={[
        {
          value: 'dark',
          label: (
            <Center>
              <IconMoonStars size={14} />
              <Box ml={10}>Dark</Box>
            </Center>
          ),
        },
        {
          value: 'light',
          label: (
            <Center>
              <IconSun size={14} />
              <Box ml={10}>Light</Box>
            </Center>
          ),
        },
      ]}
    />
  );
}
