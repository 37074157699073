import { NavLink, Text, ThemeIcon } from '@mantine/core';
import { IconMountain } from '@tabler/icons-react';
import Link from 'next/link';

interface Props {
  crag: Crag;
}

export default function CragNavLink({ crag }: Props) {
  return (
    <NavLink
      component={Link}
      href={`/crags/${crag.nameSlug}`}
      leftSection={
        <ThemeIcon variant='light' size='lg'>
          <IconMountain size={16} />
        </ThemeIcon>
      }
      label={
        <Text m={0} lh={1.25}>
          {crag.name}
        </Text>
      }
      description={
        <Text c='dimmed' fz='xs' m={0} lh={1.25}>
          {crag.area ? `${crag.area}, ${crag.stateCode}` : crag.stateCode}
        </Text>
      }
    />
  );
}
