import useSWR from 'swr';

export const useSearch = (collection: string, text: string) => {
  const { data, error, isLoading } = useSWR(
    text && `/api/search/${collection}/${text}`,
    {
      keepPreviousData: false,
    },
  );

  return {
    results: data,
    isLoading,
    isError: error,
  };
};
