'use client';

import SignInModal from '@components/auth/SignInModal';
import {
  BoulderingIcon8a,
  SportIconOutlined,
  TradIconOutlined,
} from '@components/icons';
import SchemeToggle from '@components/ui/SchemeControl/SchemeControl';
import { AppShell, Avatar, Box, NavLink } from '@mantine/core';
import { IconLogout, IconSettings, IconUser } from '@tabler/icons-react';
import { signOut, useSession } from 'next-auth/react';
import Link from 'next/link';
import { useParams, usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

export default function AppNavbar({ toggle }) {
  const pathname = usePathname();
  const params = useParams();
  const { data: session } = useSession();
  const [active, setActive] = useState('');

  const mainLinks = [
    {
      icon: <IconUser size={20} stroke={1.5} />,
      link: `/users/${session?.user.username}`,
      label: 'Profile',
      value: 'profile',
    },
    {
      icon: <BoulderingIcon8a size={20} />,
      link: `/users/${session?.user.username}/boulder`,
      label: 'Boulders',
      value: 'boulder',
    },
    {
      icon: <SportIconOutlined size={20} />,
      link: `/users/${session?.user.username}/sport`,
      label: 'Sport',
      value: 'sport',
    },
    {
      icon: <TradIconOutlined size={20} />,
      link: `/users/${session?.user.username}/trad`,
      label: 'Trad',
      value: 'trad',
    },
  ];

  const secondaryLinks = [
    {
      label: 'Settings',
      link: '/settings',
      value: 'settings',
      icon: <IconSettings size={20} stroke={1.5} />,
    },
  ];

  useEffect(() => {
    mainLinks.forEach((link) => {
      if (pathname.includes(link.link)) setActive(link.value);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, params]);

  const link = (item) => (
    <NavLink
      key={item.value}
      label={item.label}
      component={Link}
      onClick={() => {
        setActive(item.value);
        toggle(false);
      }}
      active={active === item.value && true}
      leftSection={item.icon}
      href={item.link}
    />
  );

  return (
    <AppShell.Navbar>
      {session ? (
        <>
          <AppShell.Section grow>
            <NavLink
              label={<b>{session.user.name}</b>}
              description={`@${session.user.username}`}
              leftSection={
                <Avatar
                  src={session.user.image}
                  alt={session.user.username}
                  size={34}
                />
              }
              childrenOffset={12}
              defaultOpened
              styles={{ body: { lineHeight: '1.2' } }}
            >
              {mainLinks.map((item) => link(item))}
            </NavLink>
          </AppShell.Section>
          <AppShell.Section mb='xs'>
            {secondaryLinks.map((item) => link(item))}
            <NavLink
              label='Logout'
              onClick={() => {
                signOut();
                toggle(false);
              }}
              leftSection={<IconLogout size={20} stroke={1.5} />}
            />
            <Box p='xs'>
              <SchemeToggle />
            </Box>
          </AppShell.Section>
        </>
      ) : (
        <AppShell.Section grow>
          <SignInModal />
        </AppShell.Section>
      )}
    </AppShell.Navbar>
  );
}
