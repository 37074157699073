'use client';

import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { theme } from '@lib/mantine/theme';

export default function MantineContext({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <MantineProvider defaultColorScheme='auto' theme={theme}>
      <ModalsProvider>
        <Notifications position='top-center' zIndex={2077} limit={3} />
        {children}
      </ModalsProvider>
    </MantineProvider>
  );
}
