'use client';

import { ActionIcon, Skeleton, Tooltip } from '@mantine/core';
import { IconUserMinus, IconUserPlus } from '@tabler/icons-react';
import { useSession } from 'next-auth/react';
import Link from 'next/link';

interface Props {
  user: User;
}

export default function FollowButton({ user }: Props) {
  const { data: session, status } = useSession();
  const isCurrentUser = session?.user?._id === user?._id;
  const following = true; // user?.followers?.includes(session?.user?._id);

  if (status === 'loading') {
    return <Skeleton variant='circle' height={24} />;
  }

  if (isCurrentUser) {
    return null;
  }

  if (!isCurrentUser)
    return following ? (
      <Tooltip label='Follow' zIndex={1200}>
        <ActionIcon
          disabled
          variant='light'
          component={Link}
          href={`/users/${user.username}/#follow`}
        >
          <IconUserPlus size={16} />
        </ActionIcon>
      </Tooltip>
    ) : (
      <Tooltip label='Unfollow' zIndex={1200}>
        <ActionIcon
          disabled
          variant='default'
          component={Link}
          href={`/users/${user.username}/#unfollow`}
        >
          <IconUserMinus size={16} />
        </ActionIcon>
      </Tooltip>
    );
}
