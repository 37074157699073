import { LogoIcon } from '@components/icons';
import SearchModal from '@components/ui/SearchModal';
import {
  ActionIcon,
  Anchor,
  AppShellHeader,
  Burger,
  Button,
  Group,
  Tooltip,
} from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import Link from 'next/link';

export default function AppHeader({ opened, toggle }) {
  return (
    <AppShellHeader
      p='sm'
      zIndex={1000}
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Group justify='space-between' gap={0} w='100%'>
        <Burger opened={opened} onClick={toggle} hiddenFrom='sm' size='sm' />
        <Group pl={2} gap='xs'>
          <ActionIcon
            size='lg'
            variant='filled'
            color='brand'
            component={Link}
            href='/ascents'
          >
            <LogoIcon size={28} />
          </ActionIcon>
          <Anchor
            fw={800}
            fz={26}
            lh='xs'
            c='inherit'
            underline='never'
            style={{ letterSpacing: -0.25 }}
            component={Link}
            href='/ascents'
          >
            SprayBook
          </Anchor>
          <Group ml='xl' gap='xl'>
            <Anchor c='inherit' href='/crags'>
              Crags
            </Anchor>
            <Anchor c='inherit' href='/climbs'>
              Climbs
            </Anchor>
            <Anchor c='inherit' href='/users'>
              Users
            </Anchor>
          </Group>
        </Group>
        <Group gap='xs'>
          <SearchModal />
          <Button
            variant='filled'
            component={Link}
            href='/ascents/add'
            leftSection={<IconPlus size={16} stroke={1.5} />}
            visibleFrom='xs'
          >
            Log
          </Button>
          <Tooltip label='Log Ascent'>
            <ActionIcon
              hiddenFrom='xs'
              color='brand'
              size='lg'
              component={Link}
              href='/ascents/add'
              variant='outline'
            >
              <IconPlus size={16} stroke={1.5} />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Group>
    </AppShellHeader>
  );
}
