'use client';

import { FacebookIcon, GoogleIcon } from '@components/icons';
import {
  Anchor,
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Modal,
  NavLink,
  Text,
  TextInput,
  ThemeIcon,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconLogin } from '@tabler/icons-react';
import { signIn } from 'next-auth/react';
import { useState } from 'react';

export default function SignInModal() {
  const [opened, { open, close }] = useDisclosure(false);
  const [email, setEmail] = useState<string>('');

  return (
    <>
      <Modal
        centered
        opened={opened}
        onClose={close}
        title={
          <Text fw='bold' fz='lg'>
            Welcome back!
          </Text>
        }
      >
        <Box p='md'>
          <Text mb='lg'>Sign in to your account</Text>
          <Flex gap='xs' direction='column'>
            <Button
              variant='default'
              color='dark'
              fullWidth
              leftSection={<GoogleIcon />}
              onClick={() =>
                signIn('google', {
                  redirect: false,
                })
              }
            >
              Sign in with Google
            </Button>
            <Button
              variant='default'
              color='dark'
              fullWidth
              leftSection={<FacebookIcon />}
              onClick={() =>
                signIn('facebook', {
                  redirect: false,
                })
              }
            >
              Sign in with Facebook
            </Button>
          </Flex>
          <Divider my='md' label='or' labelPosition='center' />
          <form
            autoComplete='off'
            method='post'
            action='/api/auth/signin/email'
          >
            <Flex gap='xs' direction='column'>
              <input type='hidden' name='csrfToken' defaultValue={null} />
              <TextInput
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label={
                  <Group justify='space-between' w='100%'>
                    <Text>Email</Text>
                    <Anchor fz='xs' href='#'>
                      Forgot your info?
                    </Anchor>
                  </Group>
                }
                styles={{
                  label: { width: '100%' },
                }}
                type='email'
                placeholder='email@example.com'
              />
              <Button
                type='submit'
                fullWidth
                onClick={() => signIn('email', { email, redirect: false })}
              >
                Sign in with Email
              </Button>
            </Flex>
          </form>

          <Text c='dimmed' size='xs' ta='center' my='xl'>
            Don&apos;t have an account?{' '}
            <Anchor
              href='/auth/signin'
              underline='always'
              onClick={() => signIn()}
            >
              Sign up
            </Anchor>
            .
          </Text>
        </Box>
      </Modal>

      <NavLink
        pl='md'
        mb='xs'
        label='Login'
        component='button'
        onClick={open}
        leftSection={
          <ThemeIcon variant='light' size='lg'>
            <IconLogin size={16} />
          </ThemeIcon>
        }
      />
    </>
  );
}
