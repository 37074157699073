'use client';

import AppHeader from '@components/layouts/Header';
import AppNavbar from '@components/layouts/Navbar';
import { AppShell } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import classes from './Shell.module.css';

export default function MainAppShell({
  children,
}: {
  children: React.ReactNode;
}) {
  const [opened, { toggle }] = useDisclosure();

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 250, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      classNames={classes}
      padding={0}
    >
      <AppHeader opened={opened} toggle={toggle} />
      <AppNavbar toggle={toggle} />
      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  );
}
