import {
  BoulderingIcon8a,
  SportIconOutlined,
  TradIconOutlined,
} from '@components/icons';
import { Anchor, NavLink, Text, ThemeIcon } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import Link from 'next/link';

interface Props {
  climb: Climb;
}

export default function ClimbNavLink({ climb }: Props) {
  return (
    <NavLink
      component={Link}
      href={`/climbs/${climb.nameSlug}`}
      leftSection={
        <ThemeIcon variant='light' size='lg'>
          {climb.discipline === 'boulder' && <BoulderingIcon8a />}
          {climb.discipline === 'sport' && <SportIconOutlined />}
          {climb.discipline === 'trad' && <TradIconOutlined />}
        </ThemeIcon>
        // climb.grade ? (
        //   <Avatar radius='md'>{climb.grade}</Avatar>
        // ) : (
        //   <ThemeIcon variant='light' size='lg'>
        //     {climb.discipline === 'boulder' && <BoulderingIcon8a />}
        //     {climb.discipline === 'sport' && <SportIconOutlined />}
        //     {climb.discipline === 'trad' && <TradIconOutlined />}
        //   </ThemeIcon>
        // )
      }
      label={
        <Text m={0} lh={1.25}>
          {climb.name}, {climb.grade}
        </Text>
      }
      description={
        <Anchor
          fz='xs'
          m={0}
          lh={1.25}
          c='dimmed'
          href={`/crags/${climb.crag.nameSlug}`}
        >
          {climb.crag.name}, {climb.crag.stateCode}
        </Anchor>
      }
    />
  );
}

export function ClimbListItemAdd({ climb }: Props) {
  return (
    <NavLink
      component={Link}
      href={`/ascents/add?climb=${climb.nameSlug}`}
      leftSection={
        <ThemeIcon variant='light' size='lg'>
          {climb.discipline === 'boulder' && <BoulderingIcon8a />}
          {climb.discipline === 'sport' && <SportIconOutlined />}
          {climb.discipline === 'trad' && <TradIconOutlined />}
        </ThemeIcon>
        // climb.grade ? (
        //   <Avatar radius='md'>{climb.grade}</Avatar>
        // ) : (
        //   <ThemeIcon variant='light' size='lg'>
        //     {climb.discipline === 'boulder' && <BoulderingIcon8a />}
        //     {climb.discipline === 'sport' && <SportIconOutlined />}
        //     {climb.discipline === 'trad' && <TradIconOutlined />}
        //   </ThemeIcon>
        // )
      }
      label={
        <Text m={0} lh={1.25}>
          {climb.name}
        </Text>
      }
      description={
        <Anchor
          c='dimmed'
          fz='xs'
          m={0}
          lh={1.25}
          href={`/crags/${climb.crag.nameSlug}`}
        >
          {climb.crag.name}, {climb.crag.stateCode}
        </Anchor>
      }
      rightSection={<IconPlus size={16} />}
    />
  );
}
